import { observer } from 'mobx-react-lite';
import React, { Ref, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropzone, FileItem, FileValidated, FullScreenPreview } from '@dropzone-ui/react';
import { Box, Button, Modal, Typography } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { EntryModel } from '../../../domain/model/EntryModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { colors } from '../../../theme/options/palette/const/colors';
import { SubmitButton } from '../SubmitButton';
import { AddImageVm } from './AddImageVm';

export type AddImageRef = {
  open: () => void,
  close: () => void,
}

export interface AddImageProps<T> {
  item: T;
  upsert: (item: T) => void;
  closeMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AddImage = observer(
  React.forwardRef<AddImageRef, AddImageProps<EntryModel | PoiModel>>(
    function AddImage(props: AddImageProps<EntryModel | PoiModel>, ref: Ref<AddImageRef>) {

      const vm = useVm(AddImageVm, props);
      const { t } = useTranslation();

      useImperativeHandle(ref, () => ({
        open() {
          vm.setOpen(true);
        },
        close() {
          vm.setOpen(false);
        },
      }));

      return (
        <Box>
          <Modal
            open={vm.open}
            onClose={vm.closeModal}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box bgcolor={colors.paper} height={'auto'} width={'60%'} p={5} my={8} borderRadius={2} overflow="auto"
              rowGap={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Typography variant="h1">{t('upload_image:title')}</Typography>
              <Dropzone
                onChange={vm.setFiles}
                maxFiles={vm.maxFiles}
                value={vm.files}
                onDrop={vm.setFiles}
                label={t('upload_image:modal_label')}
                accept=".JPG, .JPEG, .PNG, .jpg, .jpeg, .png"
              >
                {
                  vm.files.map((file: FileValidated) => {
                    return (
                      <FileItem
                        {...file}
                        file={file.file}
                        alwaysActive
                        key={file.id}
                        onDelete={vm.onDelete}
                        onSee={vm.setImageSrc}
                        resultOnTooltip
                        preview
                        hd
                      />
                    );
                  })}
                <FullScreenPreview
                  imgSource={vm.imageSrc}
                  openImage={!!vm.imageSrc}
                  onClose={() => vm.setImageSrc(undefined)}
                />
              </Dropzone>
              <Box display='flex' gap={2}>
                <Button fullWidth color='secondary' onClick={vm.closeModal}>{t('common:button.cancel')}</Button>
                <SubmitButton
                  fullWidth
                  color="primary"
                  disabled={!vm.files.length || (!!vm.files.length && vm.disableSubmit)}
                  variant="contained"
                  onClick={vm.handleSubmit}
                  submitting={vm.onUpload.isBusy}
                >{t('common:button.submit')}</SubmitButton>
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    }
  ),
);
