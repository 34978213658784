import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import {
  PhotoResponseDto, PoiPostRequestDto, PoiPutRequestDto, PoiResponseDto
} from '../../shared/dto';
import { AreaPostRequestDto } from '../../shared/dto/area.post.request.dto';
import { PhotoDeleteMultipleRequestDto } from '../../shared/dto/photo.delete.multiple.request.dto';
import { CheckInPoiLogResponseDto } from '../../shared/dto/poi/checkinpoilog.response.dto';
import { POI_TYPE } from '../../shared/enum/poiType.enum';
import { AsyncTask } from '../async/AsyncTask';
import { PhotoModel } from '../model/PhotoModel';
import { PoiCheckinHistoryModel } from '../model/PoiCheckinHistoryModel';
import { PoiModel } from '../model/PoiModel';
import {
  AjaxOptions, AjaxService, IHttpNotOkResponse, IHttpOkResponse
} from '../service/AjaxService';
import { SessionStore } from '../store/SessionStore';
import { Proxy } from './Proxy';

@singleton()
export class PoiProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
  }

  public getDistrictPois = new AsyncTask(async (districtId: string | undefined) => {
    const result = await this.ajax.get<PoiResponseDto[]>(`district/v2/${districtId ?? 'worldmap'}/pois`);
    if (result.ok) {
      return this.withData(result, result.data.map(PoiModel.fromDto));
    }

    return result;
  });

  public getPoisInMapBounds = async (requestDto: AreaPostRequestDto) => {
    const result = await this.ajax.post<PoiResponseDto[]>('/poi/area', requestDto);
    if (result.ok) {
      return this.withData(result, result.data.map(PoiModel.fromDto));
    }

    return result;
  }

  public getCheckinHistory = async (poiId: string) => {
    const result = await this.ajax.get<CheckInPoiLogResponseDto[]>(`/poi/${poiId}/checkin-history`);
    if (result.ok) {
      return this.withData(result, result.data.map(checkInHistory => PoiCheckinHistoryModel.fromDto(checkInHistory)));
    }

    return result;
  }

  public getTrailCamsImages = async () => {
    const result = await this.ajax.get<PhotoResponseDto[]>(`/poi/images?poiType=${POI_TYPE.CAMERA}`);
    if (result.ok) {
      return this.withData(result, result.data.map(photo => PhotoModel.fromDto(photo)));
    }

    return result;
  }

  public createPoi = async (data: PoiPostRequestDto) => {
    const result = await this.ajax.post<PoiResponseDto>('/poi', data);

    if (result.ok) {
      return this.withData(result, PoiModel.fromDto(result.data));
    }

    return result;
  }

  public createMultiplePois = async (data: PoiPostRequestDto[]) => {
    const result = await this.ajax.post<PoiResponseDto[]>('/poi/multiple', data);

    if (result.ok) {
      return this.withData(result, result.data.map(poi => PoiModel.fromDto(poi)));
    }

    return result;
  }

  public updatePoi = async (data: PoiPutRequestDto) => {
    const result = await this.ajax.put<PoiResponseDto>(
      '/poi',
      data
    );

    if (result.ok) {
      return this.withData(result,
        PoiModel.fromDto(result.data));
    }

    return result;
  }

  public uploadPhoto = async (id: string, file: File): Promise<IHttpNotOkResponse | IHttpOkResponse<PoiModel>> => {
    const fd = new FormData();
    fd.append('file', file);

    const options: AjaxOptions = {
      method: 'PUT',
      headers: {
        'X-Image-Creation-Date': file.lastModified.toString()
      }
    };

    const httpResult = await this.ajax.upload<PoiResponseDto>(`poi/photo/${id}`, fd, options);

    if (httpResult.ok) {
      return this.withData(httpResult,
        PoiModel.fromDto(httpResult.data));
    }

    return httpResult;
  }

  public deletePoi = async (poi: PoiModel) => {
    return this.ajax.delete<void>(`poi/${poi.id}`);
  }

  public deletePhoto = async (photo: PhotoModel) => {
    const requestDto = new PhotoDeleteMultipleRequestDto();
    requestDto.ids = [photo.id];

    return await this.ajax.delete<void>('photo/multiple', requestDto);
  }

  public removeTrailCamConnection = async (poiId: string): Promise<IHttpNotOkResponse | IHttpOkResponse<PoiModel>> => {
    const result = await this.ajax.put<PoiResponseDto>(`poi/disconnect/${poiId}`);

    if (result.ok) {
      return this.withData(result, PoiModel.fromDto(result.data));
    }

    return result;
  }

  public getSinglePoi = async (poiId: string): Promise<IHttpNotOkResponse | IHttpOkResponse<PoiModel>> => {
    const result = await this.ajax.get<PoiResponseDto>(`/poi/${poiId}/single`);

    if (result.ok) {
      return this.withData(result, PoiModel.fromDto(result.data));
    }

    return result;
  }

}
