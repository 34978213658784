import axios, { AxiosError, Method, ResponseType } from 'axios';

import { env } from '../../env';
import { singleton } from '../../inversify/decorator';

interface IHttpNotOkMessage {
  message: string;
  reason: unknown;
}

export type IHttpOkResponse<R> = {
  ok: true;
  data: R;
  status: number;
}

export type IHttpNotOkResponse = {
  ok: false;
  message: string;
  status: number;
}

export type IHttpResponse<R> = IHttpOkResponse<R> | IHttpNotOkResponse;

export interface AjaxOptions {
  host?: string;
  method?: Method;
  responseType?: ResponseType;
  headers?: Record<string, string>;
}

@singleton()
export class AjaxService {

  private authToken: string | null = null;

  private readonly call = async<T = unknown>(
    method: Method,
    url: string,
    data: FormData | unknown | undefined = undefined,
    headers = {},
    options: AjaxOptions = {}
  ): Promise<IHttpResponse<T>> => {
    try {
      const combinedHeaders = {
        ...headers,
        ...(options.headers || {})
      };

      const requestHeaders = {
        ...(this.authToken ? { 'Authorization': `Bearer ${this.authToken}` } : {}),
        ...(env.isDev ? { 'x-original-forwarded-for': '85.94.81.210' } : {}),
        ...combinedHeaders
      };

      const axiosConfig = {
        method,
        url,
        data,
        headers: requestHeaders,
        baseURL: options.host ?? env.api,
      };

      // Remove headers from options to prevent override
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { headers: _, ...restOptions } = options;

      const response = await axios.request<T>({
        ...axiosConfig,
        ...restOptions,
      });

      return {
        ok: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status) {
        return {
          ok: false,
          message: (axiosError.response?.data?.message as IHttpNotOkMessage)?.message,
          status: axiosError.response.status,
        };
      }

      throw error;
    }
  }

  public get = <T>(url: string): Promise<IHttpResponse<T>> => {
    return this.call<T>('GET', url);
  }

  public patch = <T>(url: string, data?: unknown): Promise<IHttpResponse<T>> => {
    return this.call<T>('PATCH', url, data);
  }

  public post = <T>(url: string, data?: unknown): Promise<IHttpResponse<T>> => {
    return this.call<T>('POST', url, data);
  }

  public put = <T>(url: string, data?: unknown): Promise<IHttpResponse<T>> => {
    return this.call<T>('PUT', url, data);
  }

  public delete = <T>(url: string, data?: unknown): Promise<IHttpResponse<T>> => {
    return this.call<T>('DELETE', url, data);
  }

  public upload = <T>(url: string, data: FormData, options: AjaxOptions = {}) => {
    return this.call<T>(
      options.method ?? 'POST',
      url,
      data,
      {},
      options
    );
  }

  public setAuthToken = (token: string | null) => {
    this.authToken = token;
  }
}
