import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { LANGUAGE, SOCIAL_LOGIN_REQUEST_TYPE } from '../enum';

export class SocialPostRequestDto {

  @IsString()
  @IsNotEmpty()
  @ApiProperty({ description: 'access-token provided by third-party API' })
  public access_token: string;

  @IsOptional()
  @IsEmail()
  @ApiProperty({ description: 'should be a valid email' })
  public email?: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;

  @IsOptional()
  @IsString()
  @ApiProperty()
  public firstName?: string;

  @IsOptional()
  @IsString()
  @ApiProperty()
  public lastName?: string;

  @IsOptional()
  @IsBoolean()
  @ApiProperty()
  public agbAccepted?: boolean;

  @IsOptional()
  @IsBoolean()
  @ApiProperty({ description: 'Is the request comming from web. Different clientIds are used for web and mobile' })
  public web?: boolean;

  @IsOptional()
  @ApiProperty()
  public code?: string;
  
  @IsOptional()
  @IsEnum(SOCIAL_LOGIN_REQUEST_TYPE)
  @ApiProperty({ description: 'Specifies whether to create a new user account or not' })
  public requestType?: SOCIAL_LOGIN_REQUEST_TYPE;
}
